import React from 'react';
import classnames from 'clsx';

import Image from '../../../../../Image/viewer/Image';
import { SuggestionItemProps } from '../../Suggestion.types';
import { useThumbnailState, ThumbnailState } from './useThumbnailState';

import style from './Item.scss';

export const Item: React.FC<SuggestionItemProps> = ({ action, id, item }) => {
  const { thumbnailState, onError, onLoad } = useThumbnailState(item);

  const imageDisplayMode = item.image?.resizeMode === 'fit' ? 'fit' : 'fill';

  return (
    <div className={style.root} title={item.title} onClick={() => action()}>
      {thumbnailState !== ThumbnailState.Hidden && (
        <div
          className={classnames(style.thumbnail, {
            [style.empty]: thumbnailState === ThumbnailState.Empty,
            [style.loading]: thumbnailState === ThumbnailState.Loading,
          })}
          data-testid="thumbnail"
        >
          {thumbnailState !== ThumbnailState.Empty && (
            <Image
              alt={item.title}
              containerId={id}
              displayMode={imageDisplayMode}
              height={item.image?.height || 55}
              id={`thumb-${id}`}
              name={item.title}
              onError={onError}
              onLoad={onLoad}
              uri={item.image!.name}
              width={item.image?.width || 73}
            />
          )}
        </div>
      )}
      <div className={style.content}>
        <span
          className={style.title}
          dangerouslySetInnerHTML={{ __html: item.title }}
        />
        {item.description && (
          <p
            className={style.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
      </div>
    </div>
  );
};
